.css-c70nd3 {
    text-align: center;
    width: 100%;
    margin-bottom: 2.5rem;
    color: white!important;
  
}
.css-niich9 {
    background-color: black!important;
   
}

li a {
    color:white !important;
    text-decoration: none!important;
}
ul {
    display: block;
    list-style-type: none!important;
 
}

.css-8cmjdh {
    text-align: left!important;
    margin-bottom: 0px!important;
}
ol, ul {
    padding-left: 0px!important;
}
.css-8now2l {
    max-width: 90%!important;
}

@media (max-width: 500px){
.ft-product {
   display: none !important;
}
}
@media (max-width: 768px){
.css-1baulvz {
    display: grid!important;
    width: 40%;
    margin-left: 30%;
}
}


