.css-15dljvc {
    max-width: 100%;
}

.blog-content {
    max-width: 100% !important;
    margin-left: 3% !important;
    margin-right: 3% !important;
    padding-top: 6rem !important;
    padding-bottom: 0rem !important;
}

.blog-card {
    max-width: 40rem !important;
}

.blog-column {
    margin-top: 1rem !important;
}

.blog-title {
    color: white !important;
}

@media(max-width:1304px) {
    .blog-content {
        max-width: 100% !important;
        margin-left: 0% !important;
        margin-right: 0% !important;
    }
    .blog-card {
        max-width: 42rem !important;
    }
    .blog-card {
        margin-top: 20px !important;
    }
}

@media(max-width:500px) {
    .blog-content {
        max-width: 94% !important;
        margin-left: 3% !important;
        margin-right: 3%;
    }
    .blog-card {
        margin-top: 20px !important;
    }
}

.blog-img {
    border-bottom-right-radius: 25px;
    border-top-left-radius: 25px;
}