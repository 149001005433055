body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* padding: 0 5%; */
  font-family: var(--bs-font-sans-serif);
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color:white;
  color:whitesmoke!important;
  background-color: black!important; 
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

}

body,html{
  overflow-x: hidden;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
    
}

a {
  text-decoration: none !important;
}

[data-whatinput='mouse'] :focus,
[data-whatinput='touch'] :focus {
    outline: none;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

.css-g5r812 {
  z-index: 0 !important;
}