.profile-content{
    max-width: 100% !important;
    padding-top: 6rem !important;
    padding-bottom: 0rem !important;
}

.profile-cards{
    max-width: 100% !important;
}

.profile-description{
    max-width: 100% !important;
}

.profile-card{
   margin-top: 1rem !important;
}

@media(max-width:1304px){
    .profile-description{
        max-width: 94% !important;
    }
}