.form-column {
    max-width: 90% !important;
    margin-left: 5% !important;
    padding-top: 6rem !important;
    padding-bottom: 0rem !important;
}

.form-cnt {
    padding: 0px !important;
}

@media screen and (max-width: 500px) {
    .form-column {
        max-width: 90% !important;
        margin-left: 5% !important;
    }
    .form-button {
        margin-top: -1rem !important;
    }
    .form-text-column {
        margin-top: 0rem !important;
    }
    .form-img {
        display: none;
    }
}

.form-img {
    border-radius: 40px;
    /* height: 660px !important; */
    width: 1200 !important;
}

.form-button {
    margin-top: -3rem !important;
}

.form-text-column {
    margin-top: -3rem !important;
}