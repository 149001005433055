.pcard-column{
    max-width: 90% !important;
    margin-left: 5% !important;
    padding-top: 6rem !important;
    padding-bottom: 0rem !important;
}

.pcard-discription{
    text-align: justify !important;
}

.pcard-image{
    width: 40% !important;
}

.pcard-text-column{
    width: 70% !important;
    margin-top: 0px;
}

@media screen and (max-width: 500px) {
    .pcard-image{
        width: 100% !important;
    }

    .pcard-text-column{
        width: 100% !important;
        margin-top: 0px;
    }
    
}