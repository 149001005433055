@import url(https://fonts.googleapis.com/css?family=Raleway:400,400i,500,500i,600,600i,700,700i,800,800i,900,900i|Roboto+Condensed:400,400i,700,700i);
@import url(https://fonts.googleapis.com/css?family=Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Montserrat);
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* padding: 0 5%; */
  font-family: var(--bs-font-sans-serif);
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color:white;
  color:whitesmoke!important;
  background-color: black!important; 
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

}

body,html{
  overflow-x: hidden;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
    
}

a {
  text-decoration: none !important;
}

[data-whatinput='mouse'] :focus,
[data-whatinput='touch'] :focus {
    outline: none;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

.css-g5r812 {
  z-index: 0 !important;
}
/* Below animations are fore modal created using React-Modal */
.ReactModal__Overlay {
    transition: transform 300ms ease-in-out;
    transition-delay: 100ms;
    transform: scale(0);
    /* transform: translateY(-150%); */
}

.ReactModal__Overlay--after-open{
    transform: scale(1);
    /* transform: translateY(0%); */
}

.ReactModal__Overlay--before-close{
    transform: scale(0);
    /* transform: translateY(-150%); */
}

.right{
    flex-grow: 0;
}

.pointer{
    padding: 5px;
    text-transform: uppercase;
    display: inline-block;
    position: relative;
}
 
  .pointer:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #1feff7;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }
  
  .pointer:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
    color: #1feff7 !important;
  }
  .fixed-top {
    z-index: 2 !important;
  }
.carousel .control-dots .dot{
    width: 20px;
    height: 18px;
  
}
.carousel .control-dots{
    bottom: 50px !important;
}
.carousel .slide img {
    width: 100%;
    height: 500px;
    vertical-align: top;
    border: 0;
}
.carousel .slide .legend{
    background-color:transparent;
    color: black;
    font-size: 40px;
    font-weight: 700;
    color:white;
    bottom: 50% !important;

}

.carousel .slide .legend2{
    transition: all .5s ease-in-out;
    position: absolute;
    bottom: 20%;
    left: 50%;
    margin-left: -45%;
    width: 90%;
    color: #fff;
    padding: 10px;
    font-size: 20px;
    text-align: center;
    transition: opacity .35s ease-in-out;
}

.footer-text {
    font-Weight: 400!important;
    color: #c2c2c2;
}
.link-color {
    color: #beb8b8;
}
.footer-spacing {
    padding-bottom: 5px;
}

.li a {
    color: #020113;
}
.a {
    color: aqua;
    text-decoration: none;
    background-color: transparent;
}

.footer-text {
    font-Weight: 400;
    color: #c2c2c2;
}
p {
    margin-top: 0;
    margin-bottom: 1rem;
}
*, :after, :before {
    box-sizing: border-box;
}
link-color {
    color: #beb8b8;
}
.fa, .far, .fas {
    font-family: "Font Awesome 5 Free";
}


@media screen and (max-width: 500px) {
    .carousel .slide .legend{ 
        font-size: 28px;
        margin-bottom: 44%;
    }
    .carousel .slide .legend2 {
        font-size: 15px;
        bottom: 20%;

    }
}
.text-color {
    font-size: 1.875rem;
    line-height: 2.25rem;
    font-weight: 700;
    --tw-text-opacity: 1;
    color: white !important;
}

.about-container {
    max-width: 90% !important;
    margin-left: 5% !important;
    padding-top: 6rem !important;
    padding-bottom: 0rem !important;
}

.about-content {
    margin-top: 1rem !important;
}

.about-description {
    margin-top: 0px;
    max-width: 50% !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
}

.about-image {
    width: 50% !important;
    margin-left: 2rem !important;
    height: 450px !important;
}

.about-card {
    margin-top: 2rem !important;
}

.about-details {
    margin-right: 10px !important;
    text-align: justify;
}

/* @media screen and (max-width: 1304px) {
    .about-container {
        max-width: 90% !important;
        margin-left: 5% !important;
        padding-top: 3rem !important;
        padding-bottom: 0rem !important;
    }
} */


@media screen and (max-width: 500px) {

    .about-container {
        display: flex !important;
        flex-direction: column !important;
        max-width: 90% !important;
        margin-left: 5% !important;
        padding-top: 3rem !important;
        padding-bottom: 0rem !important;
    }

    .about-content {
        margin-top: 0px !important;
    }

    .about-description {
        margin-top: 0px;
        max-width: 100% !important;
        margin-left: 0px !important;
        margin-right: 0px !important;
    }

    .about-image {
        width: 100% !important;
        margin-left: 0rem !important;
        height: 300px !important;
    }

    .about-card {
        margin-top: 2rem !important;
    }

    .about-details {
        margin-right: 0px !important;
        text-align: justify;
    }

    .about-title {
        text-align: center;
    }
}
.bNGRYY{
    height: 500px !important;
}


.tsm-content{
    max-width: 90% !important;
    margin-left: 5% !important;
    padding-top: 6rem !important;
    padding-bottom: 0rem !important;
}
.tsm-quote{
    font-size: 1.125rem !important;
    text-align: justify !important;
    line-height: 2rem;
}

@media(max-width:1304px){
    .tsm-testimonial {
        max-width: 100% !important;
    }  
}

@media(max-width:500px){
    .bNGRYY{
        height: 350px !important;
    }  
   
    .tsm-quote{
        font-size: 14px !important;
        text-align: justify !important;
        line-height: 2rem;
    }
}

.css-1yhymfi{
    background-color: rgba(0, 0, 0, 0.486) !important;
}

.css-1yhymfi{
    border-bottom-right-radius: 1.5rem;
}

.fTmfhr{
    border-radius: 1.25rem !important;
}

.feature-column{
    max-width: 90% !important;
    margin-left: 5% !important;
    padding-top: 6rem !important;
    padding-bottom: 0rem !important;
}

.feature-description{
    text-align: justify !important;
    font-size: 1.125rem !important;
    margin-top: 20px;
}
.buEUPw .imageContainer {
    padding: 0px !important;
}
.buEUPw .imageContainer img {
    width: 3rem !important;
    height: 3rem !important;
}

@media screen and (max-width: 500px) {
    .feature-column{
        max-width: 100% !important;
        margin-left: 0% !important;
    }

    .feature-description{
        text-align: justify !important;
        font-size: 14px !important;
        margin-top: 20px;
    }
}
.css-qqimhh {
    max-width: 100%;
}

.border {
    border-style: none!important;
}

.btn-primary {
    height: 50px !important;
    border-radius: 0px 0px 1.5rem 0px !important;
    text-transform: uppercase;
    font-weight: bold;
}

.rich-container {
    max-width: 90% !important;
    margin-left: 5% !important;
    padding-top: 6rem !important;
    padding-bottom: 0rem !important;
}

.rich-img {
    height: 400px !important;
    width: 300px !important;
    border-bottom-right-radius: 25px;
    border-top-left-radius: 25px;
}

.rich-card {
    margin-top: -5px !important;
}
.css-15dljvc {
    max-width: 100%;
}

.blog-content {
    max-width: 100% !important;
    margin-left: 3% !important;
    margin-right: 3% !important;
    padding-top: 6rem !important;
    padding-bottom: 0rem !important;
}

.blog-card {
    max-width: 40rem !important;
}

.blog-column {
    margin-top: 1rem !important;
}

.blog-title {
    color: white !important;
}

@media(max-width:1304px) {
    .blog-content {
        max-width: 100% !important;
        margin-left: 0% !important;
        margin-right: 0% !important;
    }
    .blog-card {
        max-width: 42rem !important;
    }
    .blog-card {
        margin-top: 20px !important;
    }
}

@media(max-width:500px) {
    .blog-content {
        max-width: 94% !important;
        margin-left: 3% !important;
        margin-right: 3%;
    }
    .blog-card {
        margin-top: 20px !important;
    }
}

.blog-img {
    border-bottom-right-radius: 25px;
    border-top-left-radius: 25px;
}
.react-tabs {
    border-bottom: none!important;
    margin: 0 0 10px;
    padding: 0;
}

.tab-content {
    max-width: 90% !important;
    margin-left: 5% !important;
    padding-top: 6rem !important;
    padding-bottom: 0rem !important;
}

.tab-img {
    max-width: 100% !important;
    height: 350px !important;
    border-top-left-radius: 25px;
    border-bottom-right-radius: 25px;
}

.tab-column {
    margin-top: 1rem !important;
}

.tab-title {
    color: white !important;
}

.tab-Category {
    color: white !important;
}

.tab-Occasion {
    color: white !important;
}
.profile-content{
    max-width: 100% !important;
    padding-top: 6rem !important;
    padding-bottom: 0rem !important;
}

.profile-cards{
    max-width: 100% !important;
}

.profile-description{
    max-width: 100% !important;
}

.profile-card{
   margin-top: 1rem !important;
}

@media(max-width:1304px){
    .profile-description{
        max-width: 94% !important;
    }
}
.count-content {
    max-width: 90% !important;
    margin-left: 5% !important;
    padding-top: 6rem !important;
    padding-bottom: 0rem !important;
}
.count-container{
    margin-top: 0rem !important;
    margin-bottom: 0rem !important;
}

.count-description{
    max-width: 100% !important;
}
@media screen and (max-width: 1304px) {
.count-content {
    max-width: 90% !important;
    margin-left: 5% !important;
}
}

@media screen and (max-width: 500px) {
    .count-content {
        max-width: 90% !important;
        margin-left: 5% !important;
    }
    .count-stat{
        padding:0rem !important;
    }
    .count-countcontainer{
        display: flex !important;
        flex-direction: row !important;
    }
}

.text-color{
    font-size: 1.875rem;
    line-height: 2.25rem;
    font-weight: 700;
    --tw-text-opacity: 1;
    color:  aqua!important;
}

.acd-content{
    max-width: 90% !important; 
    margin-left: 5% !important;
    padding-top: 6rem !important;
    padding-bottom: 0rem !important;
}


/* @media screen and (max-width: 1304px) {
    .acd-content {
        max-width: 90% !important;
        margin-left: 5% !important;
    }
} */
.css-c70nd3 {
    text-align: center;
    width: 100%;
    margin-bottom: 2.5rem;
    color: white!important;
  
}
.css-niich9 {
    background-color: black!important;
   
}

li a {
    color:white !important;
    text-decoration: none!important;
}
ul {
    display: block;
    list-style-type: none!important;
 
}

.css-8cmjdh {
    text-align: left!important;
    margin-bottom: 0px!important;
}
ol, ul {
    padding-left: 0px!important;
}
.css-8now2l {
    max-width: 90%!important;
}

@media (max-width: 500px){
.ft-product {
   display: none !important;
}
}
@media (max-width: 768px){
.css-1baulvz {
    display: grid!important;
    width: 40%;
    margin-left: 30%;
}
}



.form-column {
    max-width: 90% !important;
    margin-left: 5% !important;
    padding-top: 6rem !important;
    padding-bottom: 0rem !important;
}

.form-cnt {
    padding: 0px !important;
}

@media screen and (max-width: 500px) {
    .form-column {
        max-width: 90% !important;
        margin-left: 5% !important;
    }
    .form-button {
        margin-top: -1rem !important;
    }
    .form-text-column {
        margin-top: 0rem !important;
    }
    .form-img {
        display: none;
    }
}

.form-img {
    border-radius: 40px;
    /* height: 660px !important; */
    width: 1200 !important;
}

.form-button {
    margin-top: -3rem !important;
}

.form-text-column {
    margin-top: -3rem !important;
}
/* .cta-content {
     max-width: 90% !important;
     margin-left: 5% !important;
     padding-top: 6rem !important;
     padding-bottom: 0rem !important;
} */


/* .cta-text-container {
    max-width: 80% !important;
} */


/* .cta-row {
    padding-left: 0rem !important;
    padding-right: 0rem !important;
} */

@media screen and (max-width: 1304px) {
    /* .cta-text-container {
        max-width: 100% !important;
    } */
    .cta-text {
        font-size: 2.2rem !important;
        line-height: 1;
    }
}

@media screen and (max-width: 500px) {
    .cta-text-container {
        max-width: 90% !important;
    }
    .cta-text {
        font-size: 1.6rem !important;
        line-height: 1;
    }
}
.faBbzH{
    margin-top: 100px;
    color: white;
}
a {
    color: white;

}

.hero-twocolumn{
    max-width: 90% !important;
    margin-left: 5% !important;
    padding-top: 6rem !important;
    padding-bottom: 0rem !important;
}

.hero-paragraph {
    max-width: 95% !important;
    text-align: justify !important;
    color: white !important;
}

@media screen and (max-width: 1304px) {
    .hero-paragraph {
        max-width: 95% !important;
        text-align: center !important;
        color: white !important;
    }
    .hero-twocolumn {
        max-width: 90% !important;
        margin-left: 5% !important;
    }

    .her-heading{
        font-size: 2.25rem !important; 
        line-height: 1;
    }
}

.pcard-column{
    max-width: 90% !important;
    margin-left: 5% !important;
    padding-top: 6rem !important;
    padding-bottom: 0rem !important;
}

.pcard-discription{
    text-align: justify !important;
}

.pcard-image{
    width: 40% !important;
}

.pcard-text-column{
    width: 70% !important;
    margin-top: 0px;
}

@media screen and (max-width: 500px) {
    .pcard-image{
        width: 100% !important;
    }

    .pcard-text-column{
        width: 100% !important;
        margin-top: 0px;
    }
    
}
.scard-column {
    max-width: 90% !important;
    margin-left: 5% !important;
    padding-top: 6rem !important;
    padding-bottom: 0rem !important;
}

.scard-img-column {
    width: 50% !important;
}

.scard-text-column {
    margin-top: -30px !important;
    margin-left: 2rem !important;
}

.scard-description {
    text-align: justify !important;
}

.stat-img {
    border-radius: 40px;
}

@media screen and (max-width: 1304px) {
    .scard-image {
        /* width: 50% !important; */
        height: 400px !important;
    }
    .scard-heading {
        font-size: 1.8rem !important;
    }
    .scard-text-column {
        margin-top: 0px !important;
    }
    .scard-column {
        max-width: 90% !important;
        margin-left: 5% !important;
    }
}

@media screen and (max-width: 500px) {
    .scard-image {
        /* width: 50% !important; */
        height: 400px !important;
    }
    .scard-heading {
        font-size: 1.8rem !important;
    }
    .scard-img-column {
        width: 100% !important;
    }
    .scard-text-column {
        margin-top: 6rem !important;
        margin-left: 0rem !important;
    }
    .scard-statistics {
        display: flex !important;
        justify-content: space-around !important;
        flex-direction: row !important;
    }
}
.card{
    margin-top: 20px;
    margin-bottom: 20px;
}
.cardnumb{
    font-size: 75px;
    line-height: 75px;
    font-weight: 400;
    font-family: 'Teko', sans-serif;
    color: transparent;
    -webkit-text-stroke: 1px #999;
    opacity: .8;
    margin-bottom: 20px;
    
}
.card-title{
    z-index: 100;
    font-size: 35px;
    text-transform: uppercase;
    font-weight: 600;
    color:#f4b841;
    font-family: 'Teko', sans-serif;
}


.card1:hover{
    height:350px;
    background-image: url('https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR2BCl9SesAU4RIUR_mR40T7jnjBJinHrS7Yw&usqp=CAU');
    background-repeat: no-repeat;
    background-size: cover; 
}
.card2:hover{
    height:350px;
    background-image: url('https://www.google.co.in/search?q=react+logo&sxsrf=AOaemvKIDgUD9UGdXRFIExMMs2OeGDxGkg:1633677783823&source=lnms&tbm=isch&sa=X&ved=2ahUKEwjz_Oz_o7rzAhW0yzgGHQI-BaIQ_AUoAXoECAEQAw&biw=1280&bih=609&dpr=1.5#imgrc=viJ6CsTiT3pOsM');
    background-repeat: no-repeat;
    background-size: cover;
}
.card3:hover{
    height:350px;
    background-image: url('https://www.google.co.in/search?q=react+logo&sxsrf=AOaemvKIDgUD9UGdXRFIExMMs2OeGDxGkg:1633677783823&source=lnms&tbm=isch&sa=X&ved=2ahUKEwjz_Oz_o7rzAhW0yzgGHQI-BaIQ_AUoAXoECAEQAw&biw=1280&bih=609&dpr=1.5#imgrc=viJ6CsTiT3pOsM');
    background-repeat: no-repeat;
    background-size: cover;
}
/* card details start  */
section{
    padding: 100px 0;
}
.details-card {
	background: black;
}

.card-content {
	background: #ffffff;
	border: 4px;
	box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);
}

.card-img {
	position: relative;
	overflow: hidden;
	border-radius: 0;
	z-index: 1;
}

.card-img img {
	width: 100%;
	height: auto;
	display: block;
}

.card-img span {
	position: absolute;
    top: 15%;
    left: 12%;
    background: #1ABC9C;
    padding: 6px;
    color: #fff;
    font-size: 12px;
    border-radius: 4px;
    
    transform: translate(-50%,-50%);
}
.card-img span h4{
        font-size: 12px;
        margin:0;
        padding:10px 5px;
         line-height: 0;
}
.card-desc {
	padding: 1.25rem;
}

.card-desc h3 {
	color: #000000;
    font-weight: 600;
    font-size: 1.5em;
    line-height: 1.3em;
    margin-top: 0;
    margin-bottom: 5px;
    padding: 0;
}

.card-desc p {
	color: #747373;
    font-size: 14px;
	font-weight: 400;
	font-size: 1em;
	line-height: 1.5;
	margin: 0px;
	margin-bottom: 20px;
	padding: 0;
	font-family: 'Raleway', sans-serif;
}
.btn-card{
	background-color: #1ABC9C;
	color: #fff;
	box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);
    padding: .84rem 2.14rem;
    font-size: .81rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    margin: 0;
    border: 0;
    border-radius: .125rem;
    cursor: pointer;
    text-transform: uppercase;
    white-space: normal;
    word-wrap: break-word;
    color: #fff;
}
.btn-card:hover {
    background: orange;
}
a.btn-card {
    text-decoration: none;
    color: #fff;
}
/* End card section */
.row.heading h2 {
    color: #fff;
    font-size: 52.52px;
    line-height: 95px;
    font-weight: 400;
    text-align: center;
    margin: 0 0 40px;
    padding-bottom: 20px;
    text-transform: uppercase;
}
ul{
  margin:0;
  padding:0;
  list-style:none;
}
.heading.heading-icon {
    display: block;
}
.padding-lg {
	display: block;
	padding-top: 60px;
	padding-bottom: 60px;
}
.practice-area.padding-lg {
    padding-bottom: 55px;
    padding-top: 55px;
}
.practice-area .inner{ 
     border:1px solid #999999; 
	 text-align:center; 
	 margin-bottom:28px; 
	 padding:40px 25px;
}
.our-webcoderskull .cnt-block:hover {
    box-shadow: 0px 0px 10px rgba(0,0,0,0.3);
    border: 0;
}
.practice-area .inner h3{ 
    color:#3c3c3c; 
	font-size:24px; 
	font-weight:500;
	font-family: 'Poppins', sans-serif;
	padding: 10px 0;
}
.practice-area .inner p{ 
    font-size:14px; 
	line-height:22px; 
	font-weight:400;
}
.practice-area .inner img{
	display:inline-block;
}


.our-webcoderskull{
  background: url("http://www.webcoderskull.com/img/right-sider-banner.png") no-repeat center top / cover;
  
}
.our-webcoderskull .cnt-block{ 
   float:left; 
   width:100%; 
   background:#fff; 
   padding:30px 20px; 
   text-align:center; 
   border:2px solid #d5d5d5;
   margin: 0 0 28px;
}
.our-webcoderskull .cnt-block figure{
   width:148px; 
   height:148px; 
   border-radius:100%; 
   display:inline-block;
   margin-bottom: 15px;
}
.our-webcoderskull .cnt-block img{ 
   width:148px; 
   height:148px; 
   border-radius:100%; 
}
.our-webcoderskull .cnt-block h3{ 
   color:#2a2a2a; 
   font-size:20px; 
   font-weight:500; 
   padding:6px 0;
   text-transform:uppercase;
}
.our-webcoderskull .cnt-block h3 a{
  text-decoration:none;
	color:#2a2a2a;
}
.our-webcoderskull .cnt-block h3 a:hover{
	color:#337ab7;
}
.our-webcoderskull .cnt-block p{ 
   color:#2a2a2a; 
   font-size:13px; 
   line-height:20px; 
   font-weight:400;
}
.our-webcoderskull .cnt-block .follow-us{
	margin:20px 0 0;
}
.our-webcoderskull .cnt-block .follow-us li{ 
    display:inline-block; 
	width:auto; 
	margin:0 5px;
}
.our-webcoderskull .cnt-block .follow-us li .fa{ 
   font-size:24px; 
   color:#767676;
}
.our-webcoderskull .cnt-block .follow-us li .fa:hover{ 
   color:#025a8e;
}

body {
    font-family: 'Montserrat', sans-serif;

}

/* Category Ads */

#ads {
    margin: 30px 0 30px 0;
   
}

#ads .card-notify-badge {
        position: absolute;
        left: -8px;
        top: -9px;
        background: #f2d900;
        text-align: center;
        border-radius: 30px 30px 30px 30px; 
        color: #000;
        padding: 5px 10px;
        font-size: 14px;

    }

#ads .card-notify-year {
        position: absolute;
        right: -10px;
        top: -20px;
        background: #ff4444;
        border-radius: 50%;
        text-align: center;
        color: #fff;      
        font-size: 14px;      
        width: 50px;
        height: 50px;    
        padding: 15px 0 0 0;
}


#ads .card-detail-badge {      
        background: #f2d900;
        text-align: center;
        border-radius: 30px 30px 30px 30px;
        color: #000;
        padding: 5px 10px;
        font-size: 14px;        
    }

   

#ads .card:hover {
            background: #fff;
            box-shadow: 12px 15px 20px 0px rgba(46,61,73,0.15);
            border-radius: 4px;
            transition: all 0.3s ease;
        }

#ads .card-image-overlay {
        font-size: 20px;
        
    }


#ads .card-image-overlay span {
            display: inline-block;              
        }


#ads .ad-btn {
        text-transform: uppercase;
        width: 150px;
        height: 40px;
        border-radius: 80px;
        font-size: 16px;
        line-height: 35px;
        text-align: center;
        border: 3px solid #e6de08;
        display: block;
        text-decoration: none;
        margin: 20px auto 1px auto;
        color: #000;
        overflow: hidden;        
        position: relative;
        background-color: #e6de08;
    }

#ads .ad-btn:hover {
            background-color: #e6de08;
            color: #1e1717;
            border: 2px solid #e6de08;
            background: transparent;
            transition: all 0.3s ease;
            box-shadow: 12px 15px 20px 0px rgba(46,61,73,0.15);
        }

#ads .ad-title h5 {
        text-transform: uppercase;
        font-size: 18px;
    }

    .card9-container{
        display: flex;
        width: 90%;
        margin-left: 5%;
        justify-content: center;
    }

/* @media screen and (max-width: 500px) {
    .card9-container{
        display: flex;
        width: 95%;
        margin-left: 0% !important;
        justify-content: center;
    }
} */
.services-section {
    background: rgba(0, 0, 0, 0) url("https://images.pexels.com/photos/326410/pexels-photo-326410.jpeg?w=940&h=650&auto=compress&cs=tinysrgb") no-repeat scroll left top / cover;
    padding: 104px 0 113px;
    position: relative;
}
.services-section::before {
    opacity: 0.8;
}
.black-trans-bg::before {
    background: #000 none repeat scroll 0 0;
    content: "";
    height: 100%;
    left: 0;
    opacity: 0.3;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
}
.services-section-banner {
    background-image: url("http://themeinnovation.com/demo2/html/build-up/img/home1/service-banner.png");
    background-position: left bottom;
    background-repeat: no-repeat;
    background-size: contain;
    bottom: 0;
    height: 100%;
    left: 1%;
    position: absolute;
    width: 34%;
}
.services-head {
    margin: 0 0 76px;
}
.services-head .services-title {
    color: #fff;
    font-size: 30px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 32px;
    position: relative;
    text-transform: uppercase;
    margin: 0 0 5px;
}
.services-title-border {
    background: #dadada none repeat scroll 0 0;
    display: inline-block;
    height: 1px;
    margin-left: 25px;
    position: relative;
    width: 120px;
}
.services-title-border::before {
    background: #fdb801 none repeat scroll 0 0;
    content: "";
    height: 1px;
    left: -25px;
    position: absolute;
    top: 0;
    width: 20px;
}
.services-head .services-text {
    color: #fff;
    font-size: 16px;
    line-height: 22px;
    margin: 7px 0 0;
}
.service-2 .service-box {
    margin: 0 0 72px;
}
.service-2 .service-box .iconset {
    float: left;
    width: 24%;
}
.service-2 .service-box .iconset i {
    color: #fff;
    font-size: 30px;
    height: 70px;
    line-height: 70px;
    position: relative;
    text-align: center;
    width: 82px;
}
.service-2 .service-box .servicesB-content {
    float: left;
    padding-left: 8px;
    width: 76%;
}
.service-2 .service-box .servicesB-content h4 {
    color: #fff;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 22px;
    margin: 0 0 14px;
}
.service-2 .service-box .servicesB-content p {
    color: #fff;
    line-height: 24px;
}
.icon {
    color : #f4b841;
    padding:0px;
    font-size:40px;
    border: 1px solid #fdb801;
    border-radius: 100px;
    color: #fdb801;
    font-size: 28px;
    height: 70px;
    line-height: 70px;
    text-align: center;
    width: 70px;
}

* {
    box-sizing: border-box;
}
.body {
    font-family: Montserrat, sans-serif;
    font-weight: 500;
}
.section {
    height: 100vh;
  }
  
  .section.about-us {
    position: relative;
    overflow: hidden;
    height: 100vh;
    background-color: #242424;
  }

  .about-us-bg {
    position: absolute;
    left: auto;
    top: 0%;
    right: 0%;
    bottom: 0%;
    z-index: 0;
    overflow: visible;
    width: 100%;
    height: auto;
    opacity: 0.3;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
  }
  
  p{
      margin-top: 0;
  }
  .about-us-texts-wrapper {
    position: relative;
    z-index: 1;
    width: 55vw;
    margin-top: auto;
    margin-bottom: 10px;
    padding-top: 0vh;
    padding-bottom: 0vw;
   
    flex: 0 1 auto;
    
  }
  
  .section-container {
    position: relative;
    z-index: 1;
    display: flex;
    height: 100%;
    margin-right: 5vw;
    margin-left: 5vw;
    padding-left: 0vw;
  }

  .section-container.lines {
    flex-direction: column;
  }

  .lines {
    position: static;
    display: flex;
    margin-top: 30px;
    margin-bottom: auto;
    -webkit-box-pack: justify;
    flex-direction: column;
    justify-content: space-between;
  }

  
  .large-paragraph {
    margin-bottom: 4vw;
    font-size: 22px;
    color:#fff;
    line-height: 26px;
    font-weight: 300;
    text-align: justify;
  }


  .tickerwrapper {
   
    position: relative;
    width: 99.99%;
    height: 36px;
  }

  .marquee {
    position: relative;
    width: 100vw;
    max-width: 100%;
    height: 200px;
    overflow-x: hidden;
  }

  .track {
    position: absolute;
    white-space: nowrap;
    will-change: transform;
    animation: marquee 50s linear infinite;
  }

  .pinklines {
    font-family: ArchivoBlack, sans-serif;
    color: #ec028c;
    font-size: 36px;
    line-height: 36px;
    font-weight: 400;
  }
  
  .yellowlines {
    font-family: ArchivoBlack, sans-serif;
    color: #ffca06;
    font-size: 36px;
    line-height: 36px;
    font-weight: 400;
  }
  
  .bluelines {
    font-family: ArchivoBlack, sans-serif;
    color: #00b4ae;
    font-size: 36px;
    line-height: 36px;
    font-weight: 400;
  }
  
  .whitelines {
    font-family: ArchivoBlack, sans-serif;
    color: #fff;
    font-size: 36px;
    line-height: 36px;
    font-weight: 400;
  }

  .tickerright {
    position: relative;
    width: 99.99%;
    height: 36px;
    margin-top: 5px;
  }

  .marquee-two {
    position: relative;
    width: 100vw;
    max-width: 100%;
    height: 200px;
    overflow-x: hidden;
  }
  
  .track-two {
    position: absolute;
    white-space: nowrap;
    will-change: transform;
    animation: marquee-two 50s linear infinite;
  }
  
  @keyframes marquee-two {
    from { transform: translateX(-50%); }
    to { transform: translateX(0); }
  }

  .w-embed:before {
    content: " ";
    display: table;
    grid-column-start: 1;
    grid-row-start: 1;
    grid-column-end: 2;
    grid-row-end: 2;
}

.tickerlast {
    position: relative;
    width: 99.99%;
    height: 36px;
    margin-top: 5px;
}

@media screen and (max-width: 991px){
.about-us-bg {
    height: 100%;
}
.section.about-us {
    height: 100%;
  }
  
  .about-us-texts-wrapper {
    width: 75vw;
    margin-left: 5vw;
    padding-top: 6vh;
  }

  .large-paragraph {
    margin-bottom: 4vw;
    font-size: 3vw;
    line-height: 4.5vw;
  }
}


