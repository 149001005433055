.text-color {
    font-size: 1.875rem;
    line-height: 2.25rem;
    font-weight: 700;
    --tw-text-opacity: 1;
    color: white !important;
}

.about-container {
    max-width: 90% !important;
    margin-left: 5% !important;
    padding-top: 6rem !important;
    padding-bottom: 0rem !important;
}

.about-content {
    margin-top: 1rem !important;
}

.about-description {
    margin-top: 0px;
    max-width: 50% !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
}

.about-image {
    width: 50% !important;
    margin-left: 2rem !important;
    height: 450px !important;
}

.about-card {
    margin-top: 2rem !important;
}

.about-details {
    margin-right: 10px !important;
    text-align: justify;
}

/* @media screen and (max-width: 1304px) {
    .about-container {
        max-width: 90% !important;
        margin-left: 5% !important;
        padding-top: 3rem !important;
        padding-bottom: 0rem !important;
    }
} */


@media screen and (max-width: 500px) {

    .about-container {
        display: flex !important;
        flex-direction: column !important;
        max-width: 90% !important;
        margin-left: 5% !important;
        padding-top: 3rem !important;
        padding-bottom: 0rem !important;
    }

    .about-content {
        margin-top: 0px !important;
    }

    .about-description {
        margin-top: 0px;
        max-width: 100% !important;
        margin-left: 0px !important;
        margin-right: 0px !important;
    }

    .about-image {
        width: 100% !important;
        margin-left: 0rem !important;
        height: 300px !important;
    }

    .about-card {
        margin-top: 2rem !important;
    }

    .about-details {
        margin-right: 0px !important;
        text-align: justify;
    }

    .about-title {
        text-align: center;
    }
}