.scard-column {
    max-width: 90% !important;
    margin-left: 5% !important;
    padding-top: 6rem !important;
    padding-bottom: 0rem !important;
}

.scard-img-column {
    width: 50% !important;
}

.scard-text-column {
    margin-top: -30px !important;
    margin-left: 2rem !important;
}

.scard-description {
    text-align: justify !important;
}

.stat-img {
    border-radius: 40px;
}

@media screen and (max-width: 1304px) {
    .scard-image {
        /* width: 50% !important; */
        height: 400px !important;
    }
    .scard-heading {
        font-size: 1.8rem !important;
    }
    .scard-text-column {
        margin-top: 0px !important;
    }
    .scard-column {
        max-width: 90% !important;
        margin-left: 5% !important;
    }
}

@media screen and (max-width: 500px) {
    .scard-image {
        /* width: 50% !important; */
        height: 400px !important;
    }
    .scard-heading {
        font-size: 1.8rem !important;
    }
    .scard-img-column {
        width: 100% !important;
    }
    .scard-text-column {
        margin-top: 6rem !important;
        margin-left: 0rem !important;
    }
    .scard-statistics {
        display: flex !important;
        justify-content: space-around !important;
        flex-direction: row !important;
    }
}