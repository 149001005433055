.card{
    margin-top: 20px;
    margin-bottom: 20px;
}
.cardnumb{
    font-size: 75px;
    line-height: 75px;
    font-weight: 400;
    font-family: 'Teko', sans-serif;
    color: transparent;
    -webkit-text-stroke: 1px #999;
    opacity: .8;
    margin-bottom: 20px;
    
}
.card-title{
    z-index: 100;
    font-size: 35px;
    text-transform: uppercase;
    font-weight: 600;
    color:#f4b841;
    font-family: 'Teko', sans-serif;
}


.card1:hover{
    height:350px;
    background-image: url('https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR2BCl9SesAU4RIUR_mR40T7jnjBJinHrS7Yw&usqp=CAU');
    background-repeat: no-repeat;
    background-size: cover; 
}
.card2:hover{
    height:350px;
    background-image: url('https://www.google.co.in/search?q=react+logo&sxsrf=AOaemvKIDgUD9UGdXRFIExMMs2OeGDxGkg:1633677783823&source=lnms&tbm=isch&sa=X&ved=2ahUKEwjz_Oz_o7rzAhW0yzgGHQI-BaIQ_AUoAXoECAEQAw&biw=1280&bih=609&dpr=1.5#imgrc=viJ6CsTiT3pOsM');
    background-repeat: no-repeat;
    background-size: cover;
}
.card3:hover{
    height:350px;
    background-image: url('https://www.google.co.in/search?q=react+logo&sxsrf=AOaemvKIDgUD9UGdXRFIExMMs2OeGDxGkg:1633677783823&source=lnms&tbm=isch&sa=X&ved=2ahUKEwjz_Oz_o7rzAhW0yzgGHQI-BaIQ_AUoAXoECAEQAw&biw=1280&bih=609&dpr=1.5#imgrc=viJ6CsTiT3pOsM');
    background-repeat: no-repeat;
    background-size: cover;
}