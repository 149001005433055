.text-color{
    font-size: 1.875rem;
    line-height: 2.25rem;
    font-weight: 700;
    --tw-text-opacity: 1;
    color:  aqua!important;
}

.acd-content{
    max-width: 90% !important; 
    margin-left: 5% !important;
    padding-top: 6rem !important;
    padding-bottom: 0rem !important;
}


/* @media screen and (max-width: 1304px) {
    .acd-content {
        max-width: 90% !important;
        margin-left: 5% !important;
    }
} */