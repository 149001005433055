.feature-column{
    max-width: 90% !important;
    margin-left: 5% !important;
    padding-top: 6rem !important;
    padding-bottom: 0rem !important;
}

.feature-description{
    text-align: justify !important;
    font-size: 1.125rem !important;
    margin-top: 20px;
}
.buEUPw .imageContainer {
    padding: 0px !important;
}
.buEUPw .imageContainer img {
    width: 3rem !important;
    height: 3rem !important;
}

@media screen and (max-width: 500px) {
    .feature-column{
        max-width: 100% !important;
        margin-left: 0% !important;
    }

    .feature-description{
        text-align: justify !important;
        font-size: 14px !important;
        margin-top: 20px;
    }
}