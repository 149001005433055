.right{
    flex-grow: 0;
}

.pointer{
    padding: 5px;
    text-transform: uppercase;
    display: inline-block;
    position: relative;
}
 
  .pointer:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #1feff7;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }
  
  .pointer:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
    color: #1feff7 !important;
  }
  .fixed-top {
    z-index: 2 !important;
  }