.carousel .control-dots .dot{
    width: 20px;
    height: 18px;
  
}
.carousel .control-dots{
    bottom: 50px !important;
}
.carousel .slide img {
    width: 100%;
    height: 500px;
    vertical-align: top;
    border: 0;
}
.carousel .slide .legend{
    background-color:transparent;
    color: black;
    font-size: 40px;
    font-weight: 700;
    color:white;
    bottom: 50% !important;

}

.carousel .slide .legend2{
    transition: all .5s ease-in-out;
    position: absolute;
    bottom: 20%;
    left: 50%;
    margin-left: -45%;
    width: 90%;
    color: #fff;
    padding: 10px;
    font-size: 20px;
    text-align: center;
    transition: opacity .35s ease-in-out;
}

.footer-text {
    font-Weight: 400!important;
    color: #c2c2c2;
}
.link-color {
    color: #beb8b8;
}
.footer-spacing {
    padding-bottom: 5px;
}

.li a {
    color: #020113;
}
.a {
    color: aqua;
    text-decoration: none;
    background-color: transparent;
}

.footer-text {
    font-Weight: 400;
    color: #c2c2c2;
}
p {
    margin-top: 0;
    margin-bottom: 1rem;
}
*, :after, :before {
    box-sizing: border-box;
}
link-color {
    color: #beb8b8;
}
.fa, .far, .fas {
    font-family: "Font Awesome 5 Free";
}


@media screen and (max-width: 500px) {
    .carousel .slide .legend{ 
        font-size: 28px;
        margin-bottom: 44%;
    }
    .carousel .slide .legend2 {
        font-size: 15px;
        bottom: 20%;

    }
}