.css-qqimhh {
    max-width: 100%;
}

.border {
    border-style: none!important;
}

.btn-primary {
    height: 50px !important;
    border-radius: 0px 0px 1.5rem 0px !important;
    text-transform: uppercase;
    font-weight: bold;
}

.rich-container {
    max-width: 90% !important;
    margin-left: 5% !important;
    padding-top: 6rem !important;
    padding-bottom: 0rem !important;
}

.rich-img {
    height: 400px !important;
    width: 300px !important;
    border-bottom-right-radius: 25px;
    border-top-left-radius: 25px;
}

.rich-card {
    margin-top: -5px !important;
}