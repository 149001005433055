.react-tabs {
    border-bottom: none!important;
    margin: 0 0 10px;
    padding: 0;
}

.tab-content {
    max-width: 90% !important;
    margin-left: 5% !important;
    padding-top: 6rem !important;
    padding-bottom: 0rem !important;
}

.tab-img {
    max-width: 100% !important;
    height: 350px !important;
    border-top-left-radius: 25px;
    border-bottom-right-radius: 25px;
}

.tab-column {
    margin-top: 1rem !important;
}

.tab-title {
    color: white !important;
}

.tab-Category {
    color: white !important;
}

.tab-Occasion {
    color: white !important;
}