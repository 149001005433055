.bNGRYY{
    height: 500px !important;
}


.tsm-content{
    max-width: 90% !important;
    margin-left: 5% !important;
    padding-top: 6rem !important;
    padding-bottom: 0rem !important;
}
.tsm-quote{
    font-size: 1.125rem !important;
    text-align: justify !important;
    line-height: 2rem;
}

@media(max-width:1304px){
    .tsm-testimonial {
        max-width: 100% !important;
    }  
}

@media(max-width:500px){
    .bNGRYY{
        height: 350px !important;
    }  
   
    .tsm-quote{
        font-size: 14px !important;
        text-align: justify !important;
        line-height: 2rem;
    }
}

.css-1yhymfi{
    background-color: rgba(0, 0, 0, 0.486) !important;
}

.css-1yhymfi{
    border-bottom-right-radius: 1.5rem;
}

.fTmfhr{
    border-radius: 1.25rem !important;
}
