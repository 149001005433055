/* .cta-content {
     max-width: 90% !important;
     margin-left: 5% !important;
     padding-top: 6rem !important;
     padding-bottom: 0rem !important;
} */


/* .cta-text-container {
    max-width: 80% !important;
} */


/* .cta-row {
    padding-left: 0rem !important;
    padding-right: 0rem !important;
} */

@media screen and (max-width: 1304px) {
    /* .cta-text-container {
        max-width: 100% !important;
    } */
    .cta-text {
        font-size: 2.2rem !important;
        line-height: 1;
    }
}

@media screen and (max-width: 500px) {
    .cta-text-container {
        max-width: 90% !important;
    }
    .cta-text {
        font-size: 1.6rem !important;
        line-height: 1;
    }
}