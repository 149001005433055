.count-content {
    max-width: 90% !important;
    margin-left: 5% !important;
    padding-top: 6rem !important;
    padding-bottom: 0rem !important;
}
.count-container{
    margin-top: 0rem !important;
    margin-bottom: 0rem !important;
}

.count-description{
    max-width: 100% !important;
}
@media screen and (max-width: 1304px) {
.count-content {
    max-width: 90% !important;
    margin-left: 5% !important;
}
}

@media screen and (max-width: 500px) {
    .count-content {
        max-width: 90% !important;
        margin-left: 5% !important;
    }
    .count-stat{
        padding:0rem !important;
    }
    .count-countcontainer{
        display: flex !important;
        flex-direction: row !important;
    }
}
