* {
    box-sizing: border-box;
}
.body {
    font-family: Montserrat, sans-serif;
    font-weight: 500;
}
.section {
    height: 100vh;
  }
  
  .section.about-us {
    position: relative;
    overflow: hidden;
    height: 100vh;
    background-color: #242424;
  }

  .about-us-bg {
    position: absolute;
    left: auto;
    top: 0%;
    right: 0%;
    bottom: 0%;
    z-index: 0;
    overflow: visible;
    width: 100%;
    height: auto;
    opacity: 0.3;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
  }
  
  p{
      margin-top: 0;
  }
  .about-us-texts-wrapper {
    position: relative;
    z-index: 1;
    width: 55vw;
    margin-top: auto;
    margin-bottom: 10px;
    padding-top: 0vh;
    padding-bottom: 0vw;
    -webkit-box-flex: 0;
   
    flex: 0 auto;
    
  }
  
  .section-container {
    position: relative;
    z-index: 1;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    margin-right: 5vw;
    margin-left: 5vw;
    padding-left: 0vw;
  }

  .section-container.lines {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
  }

  .lines {
    position: static;
    display: flex;
    margin-top: 30px;
    margin-bottom: auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-box-pack: justify;
    flex-direction: column;
    -webkit-box-pack: justify;
    justify-content: space-between;
  }

  
  .large-paragraph {
    margin-bottom: 4vw;
    font-size: 22px;
    color:#fff;
    line-height: 26px;
    font-weight: 300;
    text-align: justify;
  }


  .tickerwrapper {
   
    position: relative;
    width: 99.99%;
    height: 36px;
  }

  .marquee {
    position: relative;
    width: 100vw;
    max-width: 100%;
    height: 200px;
    overflow-x: hidden;
  }

  .track {
    position: absolute;
    white-space: nowrap;
    will-change: transform;
    animation: marquee 50s linear infinite;
  }

  .pinklines {
    font-family: ArchivoBlack, sans-serif;
    color: #ec028c;
    font-size: 36px;
    line-height: 36px;
    font-weight: 400;
  }
  
  .yellowlines {
    font-family: ArchivoBlack, sans-serif;
    color: #ffca06;
    font-size: 36px;
    line-height: 36px;
    font-weight: 400;
  }
  
  .bluelines {
    font-family: ArchivoBlack, sans-serif;
    color: #00b4ae;
    font-size: 36px;
    line-height: 36px;
    font-weight: 400;
  }
  
  .whitelines {
    font-family: ArchivoBlack, sans-serif;
    color: #fff;
    font-size: 36px;
    line-height: 36px;
    font-weight: 400;
  }

  .tickerright {
    position: relative;
    width: 99.99%;
    height: 36px;
    margin-top: 5px;
  }

  .marquee-two {
    position: relative;
    width: 100vw;
    max-width: 100%;
    height: 200px;
    overflow-x: hidden;
  }
  
  .track-two {
    position: absolute;
    white-space: nowrap;
    will-change: transform;
    animation: marquee-two 50s linear infinite;
  }
  
  @keyframes marquee-two {
    from { transform: translateX(-50%); }
    to { transform: translateX(0); }
  }

  .w-embed:before {
    content: " ";
    display: table;
    grid-column-start: 1;
    grid-row-start: 1;
    grid-column-end: 2;
    grid-row-end: 2;
}

.tickerlast {
    position: relative;
    width: 99.99%;
    height: 36px;
    margin-top: 5px;
}

@media screen and (max-width: 991px){
.about-us-bg {
    height: 100%;
}
.section.about-us {
    height: 100%;
  }
  
  .about-us-texts-wrapper {
    width: 75vw;
    margin-left: 5vw;
    padding-top: 6vh;
  }

  .large-paragraph {
    margin-bottom: 4vw;
    font-size: 3vw;
    line-height: 4.5vw;
  }
}

