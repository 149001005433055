.faBbzH{
    margin-top: 100px;
    color: white;
}
a {
    color: white;

}

.hero-twocolumn{
    max-width: 90% !important;
    margin-left: 5% !important;
    padding-top: 6rem !important;
    padding-bottom: 0rem !important;
}

.hero-paragraph {
    max-width: 95% !important;
    text-align: justify !important;
    color: white !important;
}

@media screen and (max-width: 1304px) {
    .hero-paragraph {
        max-width: 95% !important;
        text-align: center !important;
        color: white !important;
    }
    .hero-twocolumn {
        max-width: 90% !important;
        margin-left: 5% !important;
    }

    .her-heading{
        font-size: 2.25rem !important; 
        line-height: 1;
    }
}
